import PricingCards from 'components/Website/PricingCards';
import { PRICING_SECTION } from 'constants/pages';

interface Tier {
  name: string;
  id: string;
  href: string;
  price: number;
  description: React.ReactNode;
  discountphrase: string;
  discount: number;
  mostPopular?: boolean;
  border: string;
}

interface Props {
  topText?: string;
  heading?: React.ReactNode;
  pricingTiers: Tier[];
  leadingText?: string;
}
const PricingCardsComponent = ({ topText, heading, pricingTiers, leadingText }: Props) => {
  return (
    <div id={PRICING_SECTION} className="mx-auto py-5 text-center">
      {topText && <div className="px-4 py-3 font-semibold text-brand-green-primary">{topText}</div>}
      {heading && <div className="px-4">
        <div className="relative inline-block max-w-[640px] rounded-lg border-[3px] border-brand-primary">
          <div className="bg-brand-primary/40 p-5 text-white">{heading}</div>
        </div>
      </div>}
      <div className="py-2">
        <PricingCards pricingTiers={pricingTiers} leadingText={leadingText} />
      </div>
    </div>
  );
};

export default PricingCardsComponent;
