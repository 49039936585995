import TickerTape from './TickerTape';

export default function TickerTapeBar() {
  return (
    <>
      <div className="bg-palette-black-1/95 relative w-full backdrop-blur-md">
        <TickerTape colorTheme="dark" isTransparent />
      </div>
    </>
  );
}
